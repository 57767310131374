<!--
 * @Date: 2023-10-30 19:17:56
 * @LastEditTime: 2023-12-04 15:36:45
 * @Description: file content
-->
<template>
  <div>
    <div class="section-article">
      <slot name="title">
        <div class="section-article-title" v-html="title"></div>
      </slot>
      <div class="section-article-desc">
        <slot name="desc">
          <div v-if="desc" name="desc" v-html="desc"></div>
        </slot>
      </div>
      <slot class="section-article-slot" name="contents"></slot>
    </div>
  </div>
</template>

<script>
import '../../../assets/css/normalize.css';
export default {
  name: 'SectionArticle',
  props: {
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
  },
};
</script>

<style>
.section-article-title {
  color: #000108;
  font-size: 24px;
  font-weight: 600;
}
.section-article-desc {
  font-size: 16px;
  color: #000108;
  margin-top: 8px;
  line-height: 26px;
}

@media screen and (max-width: 1280px) {
  .section-article-title {
    font-size: 20px;
  }
  .section-article-desc {
    font-size: 14px;
    margin-top: 6px;
    line-height: 20px;
  }
}
@media screen and (max-width: 990px) {
  .section-article-title {
    font-size: 18px;
  }
  .section-article-desc {
    font-size: 12px;
    margin-top: 5px;
    line-height: 20px;
  }
}
</style>
