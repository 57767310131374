<!--
 * @Date: 2023-09-26 11:03:00
 * @LastEditTime: 2024-03-24 13:24:39
 * @Description: file content
-->
<template>
  <div class="solution">
    <Viewheader :isHome="false" theme="dark" />
    <div class="solution-content">
      <div class="solution-header">
        <img
          class="banner-bg"
          src="@/assets/img/solution/banner-bg.png"
          alt=""
        />
        <div class="solution-header_cont">
          <div class="solution-title">{{ title }}</div>
          <div class="solution-text">
            <div v-for="(item, index) in contents" class="solution-item">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <SectionArticle class="background" title="背景">
        <div slot="desc" class="">
          SNS社媒引流，一直作为电商平台、独立站卖家重要的流量来源，起着扩大平台/商品曝光度、建立品牌形象、引导消费、增加用户互动等作用。<br />
          但，传统的人工社媒运营存在着以下常见问题：投放什么品没有数据支撑、视频图文素材收集+处理成本高、依赖人员经验、难以规模化运营、效果难以监控并反馈投放策略等问题。<br />
          故而，我们基于以上业务问题抽象产研解决方案，<span class="color-purple"
            >力图通过全流程闭环AI解决方案，实现SNS社媒引流的数字化、规模化、自迭代化。</span
          >
        </div>
      </SectionArticle>
      <SectionArticle class="background" title="方案的优势">
        <div slot="desc" class="">
          基于自身跨境电商平台，真实业务场景抽象出的方案，<span
            class="color-purple"
            >并得到成功数据反馈；</span
          ><br />
          <span class="color-purple">方案闭环根据投放数据反馈，自迭代。</span
          >摒弃“经验化”运营；<br />
          投放品类选择、视频图文素材收集+混剪处理、跳转站内+承接、粉丝互动等<span
            class="color-purple"
            >自动化处理。</span
          >
        </div>
      </SectionArticle>
      <SectionArticle class="contact-us" title="方案具体内容">
        <template v-slot:contents>
          <img src="../../assets/img/solution/floor-5.png" />
        </template>
      </SectionArticle>
      <SectionArticle class="ability" title="方案的核心能力">
        <ul slot="contents">
          <li>
            <p class="ability-title">(1) 基于数据投放品确定</p>
            <img src="../../assets/img/solution/floor-3.png" />
            <p>
              输出：热门keyword、站内承接商品。<br />
              主要通过平台在各个渠道的投放数据，以及竞品高订单商品，以及tiktok等高播放量带货视频等，以确定高引流的item。<br />
              对【高引流的item】进行keyword的抽取并进行关键词挖掘，同时通过【多模态语义相似】模型
              匹配可以承接的商品集。
            </p>
          </li>
          <li>
            <p class="ability-title">(2) 投放素材收集</p>
            <p>平台已拥有千万级社媒素材数据沉淀。</p>
          </li>
          <li>
            <p class="ability-title">(3) 投放素材生成（图文post+视频）</p>
            <p>平台已拥有千万级社媒素材数据沉淀。</p>
            <img src="../../assets/img/solution/floor-4.png" />
            <p>
              通过素材数据生成社媒引流图文post、引流视频，并基于不同投放渠道要求，处理视频的时长（视频批量化剪辑）、尺寸、分辨率+背景音等。
            </p>
          </li>
          <li>
            <p class="ability-title">(4) 粉丝维护+流量理解</p>
            <p>粉丝问题的批量自动回复，流量画像打标；</p>
          </li>
          <li>
            <p class="ability-title">(5) 数据统计及反馈</p>
            <p>
              【基于引流&转化效率的反馈】根据反馈，更多去投放
              引流效果好、引来uv转化效率高的 keyword+素材。
            </p>
          </li>
        </ul>
      </SectionArticle>
      <SectionArticle
        class="abutment"
        title="对接方式"
        desc="现阶段方案均以API接口方式提供，web化产品逐步开放。接入，请联系我们。"
      ></SectionArticle>
    </div>
    <Footer />
  </div>
</template>

<script>
import Viewheader from '../../components/viewheader/viewheader.vue';
import SectionArticle from './components/SectionArticle.vue';
import Footer from '../../components/foot/footer.vue';
import '../../assets/css/public.css';
import '../../assets/css/normalize.css';
export default {
  name: 'abooutus',
  components: {
    Viewheader,
    Footer,
    SectionArticle,
  },
  data() {
    return {
      title: '社媒引流AI解决方案',
      contents: ['更新时间：2023-05-25 10:12'],
      team: {
        title: '团队介绍',
        contents:
          '团队由一群经验丰富的AI专家和跨境电商从业者组成，深入了解跨境电商行业的挑战和机遇，结合先进的NLP、CV、多模态、ML等AIGC技术，为卖家提供全方位的解决方案。产品均已在真实跨境业务中应用，后续将持续开放更多能力。提供网页、SDK、私有化部署等对接方式，满足不同的应用需求，请关注我们，了解更多的最新动态。',
      },
      cards: [
        {
          company: '@数贸科技',
          title: 'Aishipgo咨询',
          qrCode: require('../../assets/img/erweima2.png'),
          desc: '如您有任何疑问\n可微信扫码咨询',
          list: ['咨询咨询咨询咨询咨询咨询', '咨询咨询咨询咨询咨询咨询'],
        },
        {
          company: '@数贸科技',
          title: 'aishipgo 应用对接-小墩',
          qrCode: require('../../assets/img/erweima1.png'),
          desc: '如您需接口批量\n调用请微信咨询',
          list: ['商务合作商务合作商务合作', '商务合作商务合作商务合作'],
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
.solution {
  width: 100%;
  /* font-family: PingFangSC-Semibold; */
  font-family: PingFang SC;
}
.solution-content {
  box-sizing: border-box;
  margin: -80px auto 0;
  padding-bottom: 80px;
}
.solution-title {
  color: #000108;
  font-size: 64px;
  text-align: center;
  margin-bottom: 16px;
  /* margin: 20px 0 30px; */
}
.solution-header {
  position: relative;
  padding-bottom: 36.1%;
  background: #d9d5e2;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-bottom: 80px;
  .banner-bg {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1440px;
  }
}
.solution-header_cont {
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
  width: 100%;
}
.solution-text {
  color: #5d5f6c;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  white-space: pre-line;
}
.solution-text .solution-item {
  /* margin-bottom: 20px; */
  text-align: center;
  font-family: PingFang-SC-Medium, PingFang-SC;
}
.solution-join-img {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.solution-join-img img {
  width: 200px;
  margin-right: 40px;
}
.background {
  width: 1280px;
  margin: auto;
  padding: 0 0 80px;
}
.contact-us,
.ability,
.abutment {
  width: 1280px;
  margin: auto;
}
.card-company {
  font-size: 14px;
  width: fit-content;
  padding: 6px 5px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  margin-bottom: 8px;
}
.card-title {
  font-size: 24px;
  margin-bottom: 24px;
}
.card-list {
  font-size: 16px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.card-list img {
  width: 20px;
  margin-right: 8px;
}
.card-desc {
  margin-top: 8px;
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  text-align: center;
}
.color-purple {
  color: rgba(108, 56, 224, 1);
}

.person-flow {
  position: relative;
  width: 100%;
  border-radius: 13px;
  margin-top: 40px;
  overflow: hidden;
  margin-bottom: 80px;
}
.flow-top {
  position: relative;
  width: 100%;
  background: #fff;
  padding-bottom: 20%;
  z-index: 1;
}
.flow_circle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 83%;
  padding-bottom: 11.7%;
}
.flow-top_circle {
  bottom: 0;
  border: 1px solid #6c38e0;
  border-width: 1px 1px 0 1px;
  border-radius: 1000px 1000px 0 0;
}
.flow-bottom_circle {
  top: 0;
  border: 1px solid #fff;
  border-width: 0px 1px 1px 1px;
  border-radius: 0 0 1000px 1000px;
}
.flow-bottom {
  position: relative;
  width: 100%;
  background: #6c38e0;
  padding-bottom: 24.6%;
}
.material {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
.earpods {
  position: absolute;
  top: 10%;
  left: 1.8%;
  width: 13.8%;
  z-index: 2;
}
.earpods img {
  width: 100%;
}
.earpods .dot {
  position: absolute;
  right: -8%;
  top: -3%;
  width: 50%;
  animation: blink 1s 15 ease-in;
}
@keyframes blink {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.4);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
.earpods2 {
  position: absolute;
  top: 3px;
  right: 0;
  width: 13.5%;
  z-index: 2;
}
.arrow {
  position: absolute;
  top: 155px;
  right: 25%;
  width: 7.5%;
  z-index: 2;
}
.flow_circle ul {
  position: absolute;
  left: 0;
  top: -26px;
  width: 100%;
  height: 200%;
  display: flex;
  box-sizing: border-box;
  padding: 0 0 0 108px;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  z-index: 10;
}
.flow_circle ul li {
  width: 34%;
  flex-shrink: 0;
}
.flow_circle ul li:nth-child(3n) {
  width: 32%;
}
.flow_circle ul li:nth-child(4),
.flow_circle ul li:nth-child(5) {
  position: relative;
  top: 58.4%;
}
.flow-title {
  font-size: 16px;
  color: #000108;
  margin-bottom: 9px;
}
.flow-title-light {
  color: #f0f2f5;
}
.flow-text {
  font-size: 12px;
  color: #98989e;
}
.flow-text-ligth {
  color: rgba(240, 242, 245, 0.5);
}
.comments {
  position: absolute;
  height: 24px;
  /* opacity: 0; */
}
.comments1 {
  left: 0;
  bottom: -120px;
}
.comments2 {
  right: -54px;
  bottom: -70px;
}
.comments3 {
  right: 40px;
  bottom: -120px;
}

.ability {
  font-size: 14px;
  color: #5d5f6c;
  line-height: 24px;
  margin-bottom: 80px;
}
.ability ul {
  margin-top: 32px;
}
.ability li {
  margin-bottom: 32px;
}
.ability li:last-child {
  margin-bottom: 0;
}
.ability-title {
  font-size: 16px;
  color: #000108;
  font-weight: 600;
  margin-bottom: 8px;
}
.contact-us {
  margin-bottom: 80px;
}
.contact-us,
.ability {
  img {
    display: block;
    margin-bottom: 12px;
    margin-top: 8px;
    width: 50%;
  }
}
@media screen and(min-width: 1440px) {
  .solution-header {
    padding-bottom: 520px;
  }
}

@media screen and (max-width: 1280px) {
  .solution-title {
    font-size: 36px;
    margin-bottom: 12px;
  }
  .solution-text {
    font-size: 14px;
    line-height: 22px;
  }
  .background,
  .abutment,
  .contact-us,
  .ability,
  .abutmen {
    width: 95%;
  }
  .card-company {
    font-size: 12px;
    padding: 5px 4px;
    margin-bottom: 6px;
  }
  .card-title {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .card-list {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .card-desc {
    margin-top: 6px;
    font-size: 12px;
  }
  .flow-title {
    font-size: 14px;
    margin-bottom: 7px;
  }
  .flow-text {
    font-size: 10px;
  }
  .ability {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 60px;
  }
  .ability-title {
    font-size: 14px;
    margin-bottom: 7px;
  }
  .flow-title {
    font-size: 12px;
    margin-bottom: 7px;
  }
  .flow-text {
    font-size: 9px;
  }
  .flow_circle ul {
    top: -19px;
  }
  .comments {
    height: 18px;
  }
  .comments1 {
    left: 0;
    bottom: -90px;
  }
  .comments2 {
    right: -40x;
    bottom: -52px;
  }
  .comments3 {
    right: 30px;
    bottom: -90px;
  }

  .common-two-column_text {
    padding: 60px 30px 0;
  }
}

@media screen and (max-width: 990px) {
  .solution-title {
    font-size: 24px;
    margin-bottom: 8px;
  }
  .solution-text {
    font-size: 10px;
    line-height: 18px;
  }
  .card-company {
    font-size: 12px;
    padding: 5px 4px;
    margin-bottom: 6px;
  }
  .card-title {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .card-list {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .card-desc {
    margin-top: 6px;
    font-size: 12px;
  }
  .flow-title {
    font-size: 14px;
    margin-bottom: 7px;
  }
  .flow-text {
    font-size: 10px;
  }
  .ability {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 60px;
  }
  .ability-title {
    font-size: 14px;
    margin-bottom: 7px;
  }
  .flow_circle ul {
    padding-left: 17%;
  }
  .flow-title {
    font-size: 9px;
    margin-bottom: 4px;
  }
  .flow-text {
    font-size: 6px;
  }
  .flow_circle ul {
    top: -13px;
  }
  .comments {
    height: 12px;
  }
  .comments1 {
    left: 0;
    bottom: -89%;
  }
  .comments2 {
    right: -40x;
    bottom: -60%;
  }
  .comments3 {
    right: 30px;
    bottom: -89%;
  }
}
</style>
