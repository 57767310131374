<!--
 * @Date: 2023-09-13 14:53:59
 * @LastEditTime: 2024-03-24 15:16:48
 * @Description: 23年9月新改版的footer
-->
<template>
  <div class="footer-wrap">
    <footer>
      <div class="footer">
        <div class="footer_module">
          <img
            class="footer_module_logo"
            src="@/assets/img/footer/logo-light.png"
          />
          <p>AI助力跨境生意增长</p>
          <!-- <ul>
            <li><img class="twitter" src="../../assets/img/footer/twitter.png" alt=""></li>
            <li><img class="telegram" src="../../assets/img/footer/telegram.png" alt=""></li>
            <li><img class="medium" src="../../assets/img/footer/medium.png" alt=""></li>
            <li><img class="github" src="../../assets/img/footer/github.png" alt=""></li>
            <li><img class="facebook" src="../../assets/img/footer/facebook.png" alt=""></li>
            <li><img class="tiktok" src="../../assets/img/footer/tiktok.png" alt=""></li>
          </ul> -->
        </div>
        <ul class="menu-list" v-if="navList.length">
          <li v-for="menu in quickEntryList" :key="menu.menuId">
            <div class="title">{{ menu.menuName }}</div>
            <p
              v-for="item in menu.children"
              :key="item.menuId"
              @click="handleJump(item)"
              :class="{ disabled: item.tips == '2' }"
            >
              {{ item.name }}
            </p>
          </li>
        </ul>

        <!-- 办公地址 -->
        <ul class="address">
          <li>
            <div class="title">办公地址</div>
            <p>北京市海淀区成府路28号优盛大厦A座7层</p>
          </li>
        </ul>

        <!-- 联系我们 -->
        <ul class="contact-us">
          <li>
            <div class="title">联系我们</div>
            <p>电话1：18201318168</p>
            <p>电话2：13810598283</p>

            <div class="QR-code-container">
              <div class="image-content">
                <img src="@/assets/img/footer/question-qr-code.png" alt="" />
                <p>如您有任何疑问<br />可微信扫码咨询</p>
              </div>
              <div class="image-content">
                <img src="@/assets/img/footer/api-qr-code.png" alt="" />
                <p>如您需接口批量<br />调用请微信咨询</p>
              </div>
            </div>
          </li>
        </ul>

        <!-- <ul class="menu-list" v-if="navList.length">
          <li v-for="menu in list" :key="menu.menuId">
            <div class="title">{{ menu.menuName }}</div>
            <p
              v-for="item in menu.children"
              :key="item.menuId"
              @click="handleJump(item)"
              :class="{ disabled: item.tips == '2' }"
            >
              {{ item.menuName }}
            </p>
          </li>
        </ul> -->
      </div>
      <div class="copyright">
        Copyright Notice © 2004 - 2023 DHgate.com All rights reserved. License
        <a href="https://beian.miit.gov.cn/">京ICP备18054285号-3</a>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  data() {
    return {
      iconList: [
        '../../../assets/img/footer/twitter.png',
        // 'telegram',
        // 'medium',
        // 'github',
        // 'facebook',
        // 'tiktok'
      ],
    };
  },

  computed: {
    ...mapGetters(['navList', 'userInfo']),
    list() {
      return (
        this.navList.filter((item) => item.menuName === '产品')[0]?.children ||
        []
      );
    },

    quickEntryList() {
      const menuList = sessionStorage.getItem('MenuTree')
        ? JSON.parse(sessionStorage.getItem('MenuTree'))
        : [];
      return [
        {
          menuName: '快捷入口',
          menuId: '0003',
          children: this.findMenuItems(menuList, [
            '002001',
            '001003001002',
            '001001002',
            '001001003001',
          ]).reverse(),
        },
      ];
    },
  },

  methods: {
    // 路由跳转
    handleJump({ url, tips, menuId }) {
      if (tips == 2) return;

      // 326 跳转至数字人直播添加 referrer 来源
      if (menuId === '001003001002') {
        window.open(
          url +
            `?referrer=${encodeURIComponent(window.location.href)}&platUserId=${
              this.userInfo.relatePlatUser.platUserId
            }`,
          '_self'
        );

        return;
      }

      if (this.$route.path == url) {
        // this.$router.go(0)
        window.location.reload();
      } else {
        // // 在当前组件的某个方法中进行路由跳转并强制刷新
        // this.$router.push(url).then(() => {
        //   window.location.reload();
        // });
        this.$router.push({
          path: url,
        });
      }
    },

    findMenuItems(menuArray, ids) {
      const result = [];

      function searchItems(array) {
        array.forEach((item) => {
          // 检查当前项的menuId是否在ids列表中
          if (ids.includes(item.menuId)) {
            result.push(item);
          }
          // 如果有children属性，递归搜索
          if (item.children && item.children.length) {
            searchItems(item.children);
          }
        });
      }

      searchItems(menuArray);
      return result;
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  background-color: #100029;
}
ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
li {
  list-style-type: none;
  text-align: center;
}
.footer-wrap {
  position: relative;
  z-index: 10;
  width: 100%;
  background: #f8f9fa;
}
.footer {
  display: flex;
  width: 1280px;
  margin: auto;
  padding: 80px 0;
  border-bottom: 1px solid rgba(0, 1, 8, 0.08);
}
.footer_module {
  flex-shrink: 0;
  width: 17.2%;
  font-size: 22px;
  /* color: #000108; */
  color: #ffffff;
}
.footer_module_logo {
  width: 89.5%;
  margin-bottom: 18px;
}
.footer_module ul {
  width: 100%;
  display: flex;
  column-gap: 9.1%;
  row-gap: 16px;
  margin-top: 66px;
}
.footer_module li {
  position: relative;
  width: 12.7%;
  padding-bottom: 12.7%;
  border-radius: 50px;
  background: #fff;
}
.footer_module li img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  transition: all 0.2s;
}
.footer_module li:hover img {
  transform: translate(-50%, -50%) scale(1.4);
}
.twitter,
.telegram,
.github {
  width: 46.4%;
}
.medium {
  width: 41%;
}
.facebook {
  width: 26.4%;
}
.tiktok {
  width: 42.9%;
}
.menu-list,
.address,
.contact-us {
  box-sizing: border-box;
  padding-right: 3.9%;
  display: flex;
  flex: 1;
  margin-left: 8.28%;
  justify-content: space-between;
}
.menu-list,
.address li {
  text-align: left;
}
.menu-list .title,
.address .title,
.contact-us .title {
  font-size: 18px;
  /* color: #000108; */
  color: #fff;
  margin-bottom: 25px;
}
.menu-list p,
.address p,
.contact-us p {
  font-size: 14px;
  /* color: #5d5f6c; */
  color: #fff;
  margin-bottom: 16px;
  transition: all 0.1s;
}
.menu-list p.disabled {
  color: #98989e;
}
.menu-list p:hover {
  color: #6c38e0;
  cursor: pointer;
  transform: translateX(5px);
  transform-origin: left center;
}
.menu-list p.disabled:hover {
  color: #98989e;
  cursor: default;
  transform: translateX(0);
}
.contact-us .QR-code-container {
  display: flex;
  gap: 12px;
}

.QR-code-container .image-content {
  background-color: #fff;
  border-radius: 4px;
  padding: 6px;
}
.image-content P {
  color: #5d5f6c;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: unset;
  background-color: unset;
}

.copyright {
  padding: 21px;
  font-size: 12px;
  line-height: 18px;
  /* color: #98989e; */
  color: #ffffff;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}
.copyright a {
  color: #98989e;
  text-decoration: none;
  cursor: pointer;
  font-size: 12px;
}
.copyright a:hover {
  text-decoration: underline;
}

@media (max-width: 1280px) {
  .footer {
    width: 90%;
    min-width: 990px;
    padding: 60px 0;
  }
  .footer_module {
    font-size: 18px;
  }
  .menu-list .title {
    font-size: 16px;
    margin-bottom: 22px;
  }
  .menu-list p {
    font-size: 12px;
    margin-bottom: 14px;
  }
  .copyright {
    padding: 16px;
    font-size: 10px;
  }
  .copyright a {
    font-size: 10px;
  }
}
@media (max-width: 989px) {
  .footer {
    display: none;
  }
}
</style>
